var Aneox;
(function (Aneox) {
  'use strict';

  var Gallery = (function () {

    var bodyElement;
    var galleryElement;
    var galleryImagesElement;
    var galleryControlsItemsElement;
    
    var isOpen;
    var gallery;
    var activeIndex;
    var hammertime;

    function Gallery() {
      var self = this;

      bodyElement = $('body');
      galleryElement = $('#gallery');
      galleryImagesElement = $('#gallery-images');
      galleryControlsItemsElement = $('#gallery-controls-items');
    }

    Gallery.prototype.show = function (galleryName) {
      gallery = _.find(Aneox.Data.Data.galleries, { id: galleryName });
      
      if (!gallery || isOpen) return;
      isOpen = true;
      
      bodyElement.addClass('noscroll');
      galleryElement.addClass('active');
      
      galleryImagesElement.empty();
      galleryControlsItemsElement.empty();
      
      _.forEach(gallery.images, function (image) {
        galleryImagesElement.append('<div class="image background-cover" style="background-image: url(' + image + ')"></div>');
        galleryControlsItemsElement.append('<li></li>')
      });
      
      activeIndex = 0;
      $('.image', galleryImagesElement).eq(activeIndex).addClass('active');
      $('li', galleryControlsItemsElement).eq(activeIndex).addClass('active');
      
      hammertime = new Hammer(document.body);
      hammertime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL, velocity: 0.33 });
      hammertime.on('swipeleft', this.next);
      hammertime.on('swiperight', this.previous);
    };

    Gallery.prototype.close = function () {
      if (!isOpen) return;
      isOpen = false;
      
      bodyElement.removeClass('noscroll');
      galleryElement.removeClass('active').removeClass('ltr-in').removeClass('ltr-out').removeClass('rtl-in').removeClass('rtl-out');
      
      hammertime.off('swipeleft');
      hammertime.off('swiperight');
      hammertime.destroy();
      hammertime = undefined;
    };

    Gallery.prototype.previous = function () {
      if (!isOpen) return;
      
      $('.image', galleryImagesElement).removeClass('active').removeClass('ltr-in').removeClass('ltr-out').removeClass('rtl-in').removeClass('rtl-out');
      $('li', galleryControlsItemsElement).removeClass('active');
      
      $('.image', galleryImagesElement).eq(activeIndex).addClass('rtl-out');
      
      activeIndex -= 1;
      if (activeIndex < 0) activeIndex = gallery.images.length - 1;
      
      $('.image', galleryImagesElement).eq(activeIndex).addClass('active').addClass('rtl-in');
      $('li', galleryControlsItemsElement).eq(activeIndex).addClass('active');
    };

    Gallery.prototype.next = function () {
      if (!isOpen) return;
      
      $('.image', galleryImagesElement).removeClass('active').removeClass('ltr-in').removeClass('ltr-out').removeClass('rtl-in').removeClass('rtl-out');
      $('li', galleryControlsItemsElement).removeClass('active');
      
      $('.image', galleryImagesElement).eq(activeIndex).addClass('ltr-out');
      
      activeIndex += 1;
      if (activeIndex >= gallery.images.length) activeIndex = 0;
      
      $('.image', galleryImagesElement).eq(activeIndex).addClass('active').addClass('ltr-in');
      $('li', galleryControlsItemsElement).eq(activeIndex).addClass('active');
    };

    MicroEvent.mixin(Gallery);

    return Gallery;
  })();

  Aneox.Gallery = Gallery;
})(Aneox || (Aneox = {}));