var Aneox;
(function (Aneox) {
  'use strict';
  
  var bottle = new Bottle();
  
  bottle.service('Library.Fonts', Aneox.Library.Fonts);
  bottle.service('Library.Locale', Aneox.Library.Locale);
  bottle.service('Library.Router', Aneox.Library.Router);
  bottle.service('Library.Analytics', Aneox.Library.Analytics, 'Library.Router');
  
  bottle.service('Animations', Aneox.Animations);
  bottle.service('Gallery', Aneox.Gallery);
  bottle.service('WineSheet', Aneox.WineSheet, 'Library.Locale');
  bottle.service('ImporterContinent', Aneox.ImporterContinent, 'Library.Locale');
  
  bottle.service('App', Aneox.App, 'Library.Fonts', 'Library.Locale', 'Library.Router', 'Library.Analytics', 'Animations', 'Gallery', 'WineSheet', 'ImporterContinent');
  
  window.app = bottle.container.App;

})(Aneox || (Aneox = {}));