var Aneox;
(function (Aneox) {
  'use strict';

  var WineSheet = (function () {

    var bodyElement;
    var wineSheetElement;
    var wineSheetContainerElement;
    var wineSheetMenuElement;
    var displayNameElement;
    var imageContainerElement;
    var pdfElements;
    
    var varietalElement;
    var areaElement;
    var orientationElement;
    var subsoilsElement;
    var colorElement;
    var productionElement;
    var descriptionElement;
    var vinificationElement;

    var wine;
    var wineLocale;

    function WineSheet(locale) {
      this.locale = locale;

      bodyElement = $('body');
      wineSheetElement = $('#wine-sheet');
      wineSheetContainerElement = $('#wine-sheet-container');
      wineSheetMenuElement = $('#wine-sheet-menu');
      displayNameElement = $('#wine-sheet-display-name');
      imageContainerElement = $('#wine-sheet-image-container');
      pdfElements = $('#wine-sheet-pdf,#wine-sheet-menu-pdf');
      
      varietalElement = $('#wine-sheet-varietal span');
      areaElement = $('#wine-sheet-area span');
      orientationElement = $('#wine-sheet-orientation span');
      subsoilsElement = $('#wine-sheet-subsoils span');
      colorElement = $('#wine-sheet-color span');
      productionElement = $('#wine-sheet-production span');
      descriptionElement = $('#wine-sheet-description span');
      vinificationElement = $('#wine-sheet-vinification span');
    }
    
    WineSheet.prototype.show = function (wineId) {
      wine = _.find(Aneox.Data.Data.wines, { id: wineId });
      wineLocale = _.find(wine.locales, { locale: this.locale.getLocale() });
      
      if (!wine || !wineLocale) return;
      
      displayNameElement.html(wine.displayName);
      pdfElements.attr('href', wineLocale.pdf);
      
      varietalElement.html(wineLocale.varietal);
      areaElement.html(wineLocale.area);
      orientationElement.html(wineLocale.orientation);
      subsoilsElement.html(wineLocale.subsoils);
      colorElement.html(wineLocale.color);
      productionElement.html(wineLocale.production);
      descriptionElement.html(wineLocale.description);
      vinificationElement.html(wineLocale.vinification);
      
      imageContainerElement.empty();
      imageContainerElement.append('<img class="centered" src="' + wine.image + '" alt="' + wine.name + '">');
      
      $(window).on('scroll touchmove mousewheel', function (event) {
        if ($(window).scrollTop() >= 100) wineSheetMenuElement.addClass('active');
        else wineSheetMenuElement.removeClass('active');
      });
    };
    
    MicroEvent.mixin(WineSheet);

    return WineSheet;
  })();

  Aneox.WineSheet = WineSheet;
})(Aneox || (Aneox = {}));