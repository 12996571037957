var Aneox;
(function (Aneox) {
  'use strict';

  var App = (function () {

    var menuOverlay;
    var menuOverlayActive;
    var canShowMenuOverlay;

    function App(fonts, locale, router, analytics, animations, gallery, wineSheet, importerContinent) {
      this.fonts = fonts;
      this.locale = locale;
      this.router = router;
      this.analytics = analytics;
      this.animations = animations;
      this.gallery = gallery;
      this.wineSheet = wineSheet;
      this.importerContinent = importerContinent;

      var viewportWidth, viewportHeight;
      var articles, previousScrollSectionHint, scrollSectionHint, scrollSectionHintNext, scrollSectionHintBackground;
      var endScrollMode, endScrollStart, endScrollOffset;
      
      // Quick and dirty desktop/mobile check
      if (screen && screen.availWidth >= 1024) window.location.href = 'http://www.domaine-hudelot-noellat.com';
      
      if ($.cookie('agecheck') === 'true')
        $('#age-check').remove();
        
      var now = new Date();
      now.setFullYear(now.getFullYear() - 18);
      var nowText = now.toISOString().substring(0, 10).split('-');
      $('#age-check h2 span').text(nowText[2] + '/' + nowText[1] + '/' + nowText[0]);
      
      var self = this;
      menuOverlay = $('#menu-overlay');

      FastClick.attach(document.body);

      this.router.bind('pageChange', function (path, node, nodeLocale) {
        self.locale.setLocale(nodeLocale.locale);

        switch (node.type) {
          case 'wine-sheet':
            self.wineSheet.show(node.id);
            $('section').not($('#wine-sheet')).remove();
            break;
          case 'importer-continent':
            self.importerContinent.show(node.id);
            $('section').not($('#importer-continent')).remove();
            break;
          default:
            if (node.id === 'home') {
              $('section').not($('#home,#domain')).remove();
              $('#domain-part-1 .menu-button').remove();
              $('#domain-part-1 .main-logo-light').remove();
              $('#domain-part-1 .advance').remove();
              $('.previous-section-hint').remove();
              previousScrollSectionHint = undefined;
            } else {
              $('section').not($('#' + node.id)).remove();
            }

            canShowMenuOverlay = true;

            $(window).on('scroll touchmove mousewheel', function (event) {
              if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight || window.scrollY === 0) return;

              var scrollTop = $(window).scrollTop();
              if (scrollTop >= 100 && !menuOverlayActive && canShowMenuOverlay) {
                menuOverlay.addClass('active');
                menuOverlayActive = true;
              } else if (scrollTop < 100 && menuOverlayActive) {
                menuOverlay.removeClass('active');
                menuOverlayActive = false;
              }
            });

            $(window).on('touchstart', function () {
              var canScrollToTop = previousScrollSectionHint !== undefined && window.scrollY === 0;
              var canScrollToBottom = scrollSectionHint !== undefined && window.innerHeight + window.scrollY === document.body.offsetHeight;
              if (!canScrollToTop && !canScrollToBottom) return;

              endScrollMode = (window.scrollY === 0) ? 'top' : 'bottom';
              endScrollStart = event.touches[0].pageY;
            });

            $(window).on('touchmove', function () {
              if (endScrollStart === undefined) return;

              endScrollOffset = event.touches[0].pageY - endScrollStart;

              switch (endScrollMode) {
                case 'bottom':
                  if (endScrollOffset >= 0) {
                    endScrollStart = undefined;
                    endScrollOffset = undefined;
                    scrollSectionHint.css('top', '0');
                    scrollSectionHintNext.css('height', '0');
                    scrollSectionHintBackground.css('opacity', '1');
                    if ($(window).scrollTop() >= 100 && !menuOverlayActive && canShowMenuOverlay) {
                      menuOverlay.addClass('active');
                      menuOverlayActive = true;
                    }
                    return;
                  }
    
                  scrollSectionHint.css('top', endScrollOffset + 'px');
                  scrollSectionHintNext.css('height', -endScrollOffset + 'px');
                  scrollSectionHintBackground.css('opacity', Math.max(0, Math.min(1, 1 + endScrollOffset / viewportHeight)) + '');
    
                  if (menuOverlayActive) {
                    menuOverlay.removeClass('active');
                    menuOverlayActive = false;
                  }
                  break;
                case 'top':
                  if (endScrollOffset <= 0) {
                    endScrollStart = undefined;
                    endScrollOffset = undefined;
                    articles.css('top', '0');
                    return;
                  }
                  articles.css('top', Math.min(80, endScrollOffset) + 'px');
                  break;
              }

              event.preventDefault();
            });

            $(window).on('touchend', function () {
              if (endScrollStart === undefined || endScrollOffset === undefined) return;

              switch (endScrollMode) {
                case 'bottom':
                  if (Math.abs(endScrollOffset) < viewportHeight * 0.20) {
                    scrollSectionHintNext.animate({ height: 0 }, {
                      step: function (now, fx) {
                        scrollSectionHint.css({ "top": -now + "px" });
                        scrollSectionHintBackground.css('opacity', Math.max(0, Math.min(1, 1 - now / viewportHeight)) + '');
                      },
                      duration: 250,
                      easing: 'linear',
                      complete: function () {
                        if ($(window).scrollTop() >= 100 && !menuOverlayActive && canShowMenuOverlay) {
                          menuOverlay.addClass('active');
                          menuOverlayActive = true;
                        }
                      }
                    });
                  } else {
                    scrollSectionHintNext.animate({ height: viewportHeight }, {
                      step: function (now, fx) {
                        scrollSectionHint.css({ "top": -now + "px" });
                        scrollSectionHintBackground.css('opacity', Math.max(0, Math.min(1, 1 - now / viewportHeight)) + '');
                      },
                      duration: 250,
                      easing: 'linear',
                      complete: function () {
                        self.router.setPath(scrollSectionHint.attr('href'));
                      }
                    });
                  }
                  break;
                case 'top':
                  if (Math.abs(endScrollOffset) < 80) {
                    articles.animate({ top: 0 }, {
                      duration: 100,
                      easing: 'linear'
                    });
                  } else {
                    self.router.setPath(previousScrollSectionHint.attr('href'));
                  }
                  break;
              }

              endScrollStart = undefined;
              endScrollOffset = undefined;
              event.preventDefault();
            });

            break;
        }

        $('section').addClass('active');

        $('#menu ul li a[router-href=' + node.id + ']').addClass('active');
        $('#menu-languages a[router-locale=' + nodeLocale.locale + ']').addClass('active');

        $(window).scrollTop(0);
        setTimeout(function () {
          // Window resize handling
          viewportWidth = Math.min($(window).width(), $(window).height());
          viewportHeight = Math.max($(window).width(), $(window).height());
    
          _.forEach($('[screen-height]'), function (element) {
            var sectionHeightElement = $(element);
            var multiplier = parseFloat(sectionHeightElement.attr('screen-height') || '1');
    
            sectionHeightElement.outerHeight(viewportHeight * multiplier);
          });
        
          $(window).scrollTop(0);
      
          $('#preloader').addClass('discarded');
        }, 500);
        setTimeout(function () { $('#preloader').remove(); }, 1000);

        articles = $('article');
        previousScrollSectionHint = $('.previous-section-hint');
        scrollSectionHint = $('.section-hint');
        scrollSectionHintNext = $('.section-hint-next');
        scrollSectionHintBackground = $('.section-hint-background');
      });

      this.locale.bind('localeChange', function (locale) {
        $('#wines-list').empty();
        _.forEach(Aneox.Data.Data.wineCategories, function (wineCategory) {
          var wines = _.where(Aneox.Data.Data.wines, { category: wineCategory.id });
          $('#wines-list').append('<h3>' + wineCategory.name + '</h3>');

          _.forEach(wines, function (wine) {
            $('#wines-list').append('<a class="wine" href="' + self.router.getNodePath(wine.id, locale) + '">' + wine.name + '</a>');
          });
        });

        $('#importers-continent-list').empty();
        _.forEach(Aneox.Data.Data.importerContinents, function (importerContinent) {
          var localizedData = _.find(importerContinent.locales, { locale: locale });
          $('#importers-continent-list').append('<a class="continent" href="' + self.router.getNodePath(importerContinent.id, locale) + '">' + localizedData.name + '</a>');
        });
      });
      
      $('#gallery').on('scroll touchmove mousewheel', function (event) {
        return event.preventDefault();
      });
    }

    App.prototype.showMenu = function () {
      $('body').addClass('noscroll');
      $('#menu').addClass('active');
      menuOverlay.removeClass('active');
      menuOverlayActive = false;
    };

    App.prototype.hideMenu = function () {
      $('body').removeClass('noscroll');
      $('#menu').removeClass('active');
      if ($(window).scrollTop() >= 100 && !menuOverlayActive && canShowMenuOverlay) {
        menuOverlay.addClass('active');
        menuOverlayActive = true;
      }
    };

    App.prototype.ageCheckResult = function (result) {
      if (!result) return;
      
      $.cookie('agecheck', 'true');
      $('#age-check').addClass('discarded');
      setTimeout(function () { $('#age-check').remove(); }, 500);
    }

    return App;
  })();

  Aneox.App = App;
})(Aneox || (Aneox = {}));